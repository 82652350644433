<div class="container">
  
  <div class="alert" *ngIf="alertMessage">{{alertMessage}}</div>

  <div class="flex">

    <section class="section-1">
      <h2>1. Start your Webcam</h2>
      <div class="videos">
        <span>
          <h3>Local Stream</h3>
          <video id="webcamVideo" [srcObject]="localStream" autoplay playsinline></video>
        </span>
        <span>
          <h3>Remote Stream</h3>
          <video id="remoteVideo" [srcObject]="remoteStream" autoplay playsinline></video>
        </span>
      </div>
      <button id="webcamButton" [disabled]="webcamButtonDisabled" (click)="startWebcam()">Start webcam</button>
    </section>

    <section>
      <h2>2. Create a new Call</h2>
      <button id="callButton" [disabled]="callButtonDisabled" (click)="createOffer()">Create Offer</button>
    </section>

    <section>
      <h2>3. Join a Call</h2>
      <p>Answer the call from a different browser window or device</p>
      <div>
        <input type="text" [formControl]="callInput" placeholder="Call ID" />
        <button id="answerButton" [disabled]="answerButtonDisabled" (click)="answerCall()">Answer</button>
      </div>
    </section>

    <section>
      <h2>4. Hangup</h2>
      <button id="hangupButton" [disabled]="hangupButtonDisabled">Hangup</button>
    </section>

  </div>
</div>