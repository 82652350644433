import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { initializeApp } from 'firebase/app';
import { addDoc, collection, doc, DocumentData, getDoc, getFirestore, onSnapshot,  query, where, updateDoc, DocumentSnapshot, getDocs, QuerySnapshot, setDoc } from "firebase/firestore"; 

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {

  callId: string = '';

  answerCallId!: string;

  localStream: any = null;
  remoteStream: any = null;

  webcamVideo: any = document.getElementById('webcamVideo');
  remoteVideo: any = document.getElementById('remoteVideo');

  // buttons diabled states
  webcamButtonDisabled: boolean = false;
  callButtonDisabled: boolean = true;
  answerButtonDisabled: boolean = true;
  hangupButtonDisabled: boolean = true;

  // alerts
  alertMessage: string = '';

  app = initializeApp({
    apiKey: "AIzaSyDbTXGyzAWWeZYUjFwRToxsr6Q8dFeIko0",
    authDomain: "webrtc-in-100-seconds.firebaseapp.com",
    projectId: "webrtc-in-100-seconds",
    storageBucket: "webrtc-in-100-seconds.appspot.com",
    messagingSenderId: "917179200936",
    appId: "1:917179200936:web:b6aa7c4130c728b80ed99d"
  });
  firestore = getFirestore(this.app);

  private servers = {
    iceServers: [
      {
        urls: ['stun:stun1.l.google.com:19302', 'stun:stun2.l.google.com:19302'],
      },
    ],
    iceCandidatePoolSize: 10,
  }; 
  pc: RTCPeerConnection = new RTCPeerConnection(this.servers);

  callInput = new FormControl('');

  constructor() {
    console.log('v1');
  }

  ngOnInit() {

    setTimeout(() => {
      this.startWebcam();
    }, 1000);
    
  }
  
  async startWebcam() {

    this.localStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
    this.remoteStream = new MediaStream();
  
    // Push tracks from local stream to peer connection
    this.localStream.getTracks().forEach((track: any) => {
      this.pc.addTrack(track, this.localStream);
    });
  
    // Pull tracks from remote stream, add to video stream
    this.pc.ontrack = event => {
      event.streams[0].getTracks().forEach(track => {
        this.remoteStream.addTrack(track);
      });
    };
  
    this.callButtonDisabled = false;
    this.answerButtonDisabled = false;
    this.webcamButtonDisabled = true;

  }

  // Create an offer
  async createOffer() {

    const docRef = await addDoc(collection(this.firestore, 'calls'), {});
    
    this.callId = docRef.id;
    this.callInput.setValue(this.callId);

    addDoc(collection(this.firestore, 'calls', this.callId, 'offerCandidates'), {});
    // addDoc(collection(this.firestore, 'calls', this.callId, 'answerCandidates'), {}); /// move this to answerCall

    this.pc.onicecandidate = event => {
      if (event.candidate) {
        addDoc(collection(this.firestore, 'calls', this.callId, 'offerCandidates'), event.candidate.toJSON());
      }
    };

    // Create offer
    const offerDescription = await this.pc.createOffer();
    await this.pc.setLocalDescription(offerDescription);

    const offer = {
      sdp: offerDescription.sdp,
      type: offerDescription.type,
    };

    console.log('offer', offer);

    // set the offer
    const callDoc = doc(this.firestore, 'calls', this.callId);
    console.log( 'set the offer callDoc', callDoc );

    // await updateDoc(callDoc, { offer });
    setDoc(callDoc, { offer }, { merge: true });



    // listen for the remote answer
    onSnapshot(callDoc, (snapshot: DocumentSnapshot) => {

      console.log('snapshot data', snapshot.data());

      const data: any = snapshot.data();
      if (!this.pc.currentRemoteDescription && data?.['answer']) {
        const answerDescription = new RTCSessionDescription(data?.['answer']);
        this.pc.setRemoteDescription(answerDescription);
      }

    });

    onSnapshot(query(collection(this.firestore, 'calls', this.callId, 'answerCandidates')), (snapshot: QuerySnapshot) => {
      console.log('answerCandidates snapshot', snapshot.docChanges());

      snapshot.docChanges().forEach((change) => {
        console.log('change.doc.data()', change.doc.data());
        if (change.type === 'added' && change.doc.data()['sdpMid']) {
          const candidate = new RTCIceCandidate(change.doc.data());
          this.pc.addIceCandidate(candidate);
        }
      });

    });

    this.hangupButtonDisabled = false;

  }

  async answerCall() {

    if (!this.callInput.value) return;

    this.answerCallId = this.callInput.value;
    console.log('this.answerCallId', this.answerCallId);
        
    const callDoc = doc(this.firestore, 'calls', this.answerCallId);
    const docRef = await getDoc(callDoc);
    const callData = docRef.data();

    console.log('answer callDoc', callDoc);
    console.log('docRef', docRef);
    console.log('docRef data', docRef.data());

    this.pc.onicecandidate = event => {
      if (event.candidate) {
        addDoc(collection(this.firestore, 'calls', this.answerCallId, 'answerCandidates'), event.candidate.toJSON());
      }
    };

    const offerDescription = callData?.['offer'];
    await this.pc.setRemoteDescription(new RTCSessionDescription(offerDescription));
    console.log('offerDescription', offerDescription);

    const answerDescription = await this.pc.createAnswer();
    await this.pc.setLocalDescription(answerDescription);
    console.log('answerDescription', answerDescription);

    const answer = {
      type: answerDescription.type,
      sdp: answerDescription.sdp,
    };
  
    console.log('answer', answer);

    // add answer to the collection
    setDoc(callDoc, { answer }, { merge: true });
    // await updateDoc(callDoc, this.answerCallId, {answer});

    const offerCandidates = collection(this.firestore, 'calls', this.answerCallId, 'offerCandidates');
    console.log('offerCandidates', offerCandidates);


    console.log('here');
    onSnapshot(query(collection(this.firestore, 'calls', this.answerCallId, 'offerCandidates')), (snapshot: QuerySnapshot) => {
      console.log('answerCandidates snapshot', snapshot.docChanges());

      snapshot.docChanges().forEach((change) => {
        console.log('change.doc.data()', change.doc.data());
        if (change.type === 'added' && change.doc.data()['sdpMid']) {
          const candidate = new RTCIceCandidate(change.doc.data());
          this.pc.addIceCandidate(candidate);
        }
      });

    });

    // const snapShot = await getDocs(offerCandidates);
    // snapShot.forEach(doc => {
    //   // console.log('doc', doc);
    //   let data = doc.data();
    //   console.log('data', data);
    //   this.pc.addIceCandidate(new RTCIceCandidate(data));
    // });

  
    this.hangupButtonDisabled = false;

  }
  

}
